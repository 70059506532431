// 90714
@import '../helpers/componentHeader';

.storepage-offeringtype {
    justify-content: center;
    .card-deck {
        @media (max-width: 768px) {
            flex-flow: column;
        }
        .col-offeringtype {
            max-width: 490px;
            border: 1px solid $secondary-light;
            border-top: 2px solid $bon-blue;
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
            border-radius: 0px;
            @media (max-width: 768px) {
                max-width: 100%;
                margin-bottom: 30px;
                
            }
            &.bg-seconday-lowlight {
                background-color: $secondary-lowlight ;
                @media (max-width: 768px) {
                    max-width: 100%;
                    margin-bottom:0px;
                    
                }
            }
            .experience-component {
                display:flex;
                flex-flow: column;
                flex: 1 0 0%;
                .card-header {
                    border: 0px;
                    padding: 32px 32px 16px;
                    background: none;

                    .offeringtype-title {
                        text-align: center;
                        width: auto;
                        margin: 0;
                        font-family: $secondary-font-medium;
                        font-size: 24px;
                        line-height: 36px;
                        color: $primary-darkest;
                        font-weight: normal;
                        letter-spacing: 0;
                        @include media-breakpoint-down(sm) {
                            font-size: 18px;
                            line-height: 26px;
                        }

                        &:after {
                            content: "";
                            height: 2px;
                            width: 64px;
                            background: $yellow;
                            margin-top: 10px;
                            margin-bottom: 5px;
                            margin-right: auto;
                            margin-left: auto;
                            display: block;
                        }

                    }
                }
                .card-body {
                    padding: 16px 32px 40px;

                .offeringtype-check-styled {
                    list-style: none;
                    padding: 0px;
                    margin-bottom: 0px;
                    li {
                        margin-bottom: 20px;
                        padding-left: 42px;
                        position: relative;
                        &:before {
                            position: absolute;
                            left: 0;
                            content: "";
                            width: 18px;
                            height: 18px;
                            border-radius: 20px;
                            background-image: url(../../images/icon-list-check.svg);
                            background-color: $bon-blue;
                            background-repeat: no-repeat;
                            background-position: center center;
                            background-size: 10px;
                            top: 8px;
                            @media (max-width: 768px) {
                                top: 4px;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                        // 69356
                        .card-title {
                            font-family: $primary-font-bold;
                            font-weight: bold;
                            text-align: left;
                            color: $secondary-black;
                            font-size: 16px;
                            line-height: 26px;
                            margin-bottom: 0px;
                            @media (max-width: 768px) {
                                font-size: 12px;
                            }
                        }
                        .card-text {
                            font-family: $primary-font;
                            text-align: left;
                            color: $bright-gray;
                            font-size: 16px;
                            line-height: 26px;
                            @media (max-width: 768px) {
                                font-size: 12px;
                            }
                        }
                    }
                }
                .btn-link {
                    font-family: $primary-font-semibold;
                    text-decoration: underline;
                    font-size: 16px;
                    line-height: 26px;
                    color: $primary !important;
                    padding-left: 42px;
                    @media (max-width: 768px) {
                        font-size: 12px;
                    }
                }
            }
            .card-footer {
                padding: 0px 32px 40px;
                background: none;
                .btn-secondary {
                    color: $secondary-black;
                }
            }
        }
    }
    }
}